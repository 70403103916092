<script setup lang="ts">
import type { ContactMethodProps } from './ContactMethod.props'
// import type { ContactUsIconType } from '@design-system/mocks/helpIconTypes'
import { iconsMap } from './ContactMethod.iconsMapping'
import type { IconType } from './ContactMethod.iconsMapping'

withDefaults(defineProps<ContactMethodProps>(), {
  size: 'M',
})
const emit = defineEmits<{
  (e: 'click', cta: string, type: string): void
}>()
</script>

<template>
  <div
    class="text-book-7 bg-primitives-off-white flex h-full flex-col justify-between overflow-hidden"
    :class="[
      verticalCard
        ? 'gap-0'
        : 'w-full min-w-[311px] gap-4 p-6 md:min-w-[265px] md:gap-8',
    ]"
  >
    <div
      class="flex flex-col"
      :class="[verticalCard ? 'gap-0' : 'gap-y-6 md:gap-y-4']"
    >
      <div
        class="flex w-full gap-x-2"
        :class="[
          verticalCard
            ? 'md:gap-md flex-col items-start gap-3'
            : 'flex-row items-center',
        ]"
      >
        <component
          :is="iconsMap[icon as IconType]"
          v-if="icon"
          class="h-8 w-8 shrink-0"
          :class="{ 'md:w-xxl md:h-xxl': verticalCard }"
          :aria-hidden="true"
        />
        <h3 class="text-medium-4">{{ name }}</h3>
      </div>

      <UtilsMarkdown
        v-if="description"
        :content="description"
        class="text-light-6"
        :class="{ 'mt-1 md:mt-2': verticalCard }"
      />
    </div>

    <AtomsCta
      v-if="cta?.internalLink?.link"
      anatomy="secondary"
      size="s"
      :class="{ 'mt-sm md:mt-lg': verticalCard }"
      :button-attrs="{
        type: 'button',
      }"
      type="button"
      :aria-label="cta?.ariaLabel"
      @click="
        emit(
          'click',
          cta?.internalLink?.link!,
          cta?.internalLink?.type || 'slug'
        )
      "
    >
      <template #label>{{ cta?.label }}</template>
    </AtomsCta>
    <MoleculesLinkWrapper
      v-else
      anatomy="secondary"
      size="s"
      :class="{ 'mt-sm md:mt-lg': verticalCard }"
      :aria-label="cta?.ariaLabel"
      :to="cta?.externalLink?.link"
      :target="cta?.externalLink?.target || 'blank'"
      :external="!!cta?.externalLink?.link"
    >
      <template #label> {{ cta?.label }} </template>
    </MoleculesLinkWrapper>
  </div>
</template>
